@import "~govuk-frontend/govuk/base";

.role-management-container{
    .govuk-table__cell {
        font-weight: 100;
    }

    @include govuk-media-query($from: desktop) {
		tbody > .govuk-table__row:hover {
            background-color: #e0e0e0;
        }
	}
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.25 rem; 
  }
