.govuk-table__cell .govuk-button.search-button {
    &:last-child {
      height: 2.28rem;
    }
}

.govuk-button.search-button {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 19.75rem) {
    .govuk-table__cell {
      .govuk-button.search-button {
        &:first-of-type + .govuk-button.search-button {
          margin-top: 0.5rem;
        }
      }
    }
  }