.fileUploadField {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.removeFileButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: none;
  color: white;
  background-color: #E5173F;
  padding: 0;
  margin: 4px 0.25rem 0 4px;
  cursor: pointer;
}