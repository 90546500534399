@import "~govuk-frontend/govuk/base";
@import '../../assets/scss/colours';

.modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  &-box {
    display: block;
    background: white;
    width: 85%;
    height: 20.9375;
    padding: 1rem;
    border-radius: 1rem;

    @include govuk-media-query($from: tablet) {
      width: 450px;
      height: 33.125;
    }
  }

  .govuk-warning-text__icon {
    border: 3px solid $tango;
    color: #ffffff;
    background: $tango;
  }
}

.errormodal {
  &-box {
    display: block;
    background: white;
    width: auto;
    padding: 1rem;
    border-radius: 1rem;
    overflow: auto;

    @include govuk-media-query($from: mobile) {
      width: 85%;
      min-height: 150px;
      justify-content: center;
      position: relative;
      overflow: auto;
    }

    @include govuk-media-query($from: tablet) {
      width: 450px;
      max-height: 800px;
      justify-content: center;
      position: relative;
      overflow: auto;
    }
  }
}

.statusmodal {
  &-box {
    display: block;
    background: white;
    width: auto;
    padding: 1rem;
    border-radius: 1rem;
    overflow: auto;

    @include govuk-media-query($from: mobile) {
      width: 85%;
      max-height: 100%;
      min-height: 150px;
      justify-content: center;
      position: relative;
      overflow: auto;
    }

    @include govuk-media-query($from: tablet) {
      width: 450px;
      max-height: 800px;
      justify-content: center;
      position: relative;
      overflow: auto;
    }
  }
}

.close-button {
  border: none;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  text-align: right;
  font-size: 2.5rem;
  float: right
}