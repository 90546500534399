.error-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .characters__count {
    margin-top: 0.25rem;
    font-size: 1rem;
    display: block;
  }
  
  .govuk-error-message {
    margin-top: 0.25rem;
    display: block;
  }